<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="(edit = true), (add = false)"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-text-field
          v-model="icode"
          label="単位ID"
          prepend-icon="mdi-account"
          outlined
          readonly
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="name"
          label="単位名"
          prepend-icon="mdi-account"
          outlined
          :rules="[Rules.Required, tanniNameRule]"
        ></v-text-field>
        <v-radio-group
          v-model="selectDelete"
          row
          label="レコード状態:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import Tanni from "@/models/Tanni";

const SelectsDelete = {
  Delete: 0,
  Default: 1
};

export default {
  name: "TanniDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      icode: "",
      name: "",
      onflg: true,
      selectDelete: SelectsDelete.Default,
      SelectsDelete,
      tanniNameRule: value => {
        const errorValue = "単位名を１０文字以内で入力してください。";
        return value.length <= 10 || errorValue;
      }
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    complete() {
      console.log("complete", this.icode);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.icode);

      this.$loading();

      try {
        const param = new Tanni(this.icode, this.name, this.selectDelete);

        console.log("submit_param", param);
        if (this.add) {
          await this.$post(this.Paths.tanni, param);
        } else {
          await this.$put(this.Paths.tanni, param);
        }
        this.$info("更新しました。", "単位マスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/tanni";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getInsertId() {
      console.log("tanniMaxId");
      const maxId = await this.$get(this.Paths.tanniMaxId);
      console.log(maxId);
      this.icode = maxId;
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;
    console.log("filter", this.params.filter);
    const test = this.params.args;
    console.log("args", test);

    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      if (!this.add) {
        this.icode = test.icode;
        this.name = test.name;

        if (test.isValid === "0") {
          this.selectDelete = this.SelectsDelete.Delete;
        }
      } else {
        await this.getInsertId();
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
