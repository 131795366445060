export default class Tanni {
  icode: string;
  name: string;
  isValid: string;
  constructor(icode = "", name = "", isValid = "") {
    this.icode = icode;
    this.name = name;
    this.isValid = isValid;
  }
}
